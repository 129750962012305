<!--
 * @Descripttion:
 * @Author: luxiyuan
 * @Date: 2020-06-28 14:15:35
 * @LastEditors: faf
 * @LastEditTime: 2022-08-16 14:12:25
-->
<template>
  <div class="content bgFFF">
    <!-- <el-button type="text" @click="$router.go(-1)"> < 返回</el-button> -->
    <div class="pageTitle">订单详情</div>
    <el-form label-width="120px" ref="form" :model="formInline" :rules="rules">
      <div class="section-title">订单信息</div>
      <el-form-item label="订单状态:">{{ formInline.stateDesc }}</el-form-item>
      <el-form-item label="支付时间:">{{ formInline.payTime | timeFilter(that) }}</el-form-item>
      <!-- <el-row>
        <el-col :span="8">
          <el-form-item label="车牌号:" v-if="!$route.query.isEdit">{{ formInline.plateNumber }}</el-form-item>
          <el-form-item label="车牌号:" v-if="$route.query.isEdit" prop="plateNumber">
            <el-input
              :maxlength="8"
              v-model="formInline.plateNumber"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="车牌颜色:" v-if="!$route.query.isEdit">{{ formInline.plateColor }}</el-form-item>
          <el-form-item :label="$t('searchModule.License_plate_color')" v-if="$route.query.isEdit" prop="plateColorCode">
            <el-select
              v-model="formInline.plateColorCode"
              placeholder="请选择车牌颜色"
            >
              <el-option
                :label="v.desc"
                :value="v.code"
                v-for="v in colorList"
                :key="v.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row> -->
      <div v-if="!$route.query.isEdit">
        <el-form-item label="车牌号码:">
          <span v-for="(item, index) in formInline.plateList" :key="index">
            <el-tag>{{ item.plateColor }} : {{ item.plateNumber }}</el-tag>
          </span>
        </el-form-item>
      </div>
      <div v-if="$route.query.isEdit">
        <el-form-item
          label="车牌号码:"
          :required="true"
          :key="ind"
          v-for="(val, ind) in formInline.plateList"
        >
          <el-form-item
            :prop="'plateList.' + ind + '.numberName'"
            :rules="[{ required: true, message: '请选择车牌号码', trigger: 'change' }]"
            style="width: 100px; display: inline-block"
          >
            <el-select v-model="val.numberName" placeholder="请选择">
              <el-option
                :disabled="item.disabled && item.disabled"
                v-for="(item, platInd) in platerNumberLists"
                :key="platInd"
                :label="item.numberName"
                :value="item.numberName"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            :prop="'plateList.' + ind + '.plateNumber'"
            :rules="[
              { required: true, message: '请输入车牌号码', trigger: 'change' },
              { required: true, validator: checkNumber, trigger: 'change' },
            ]"
            style="width: 210px; padding: 0 20px 0 5px; display: inline-block"
          >
            <!-- :disabled="!parkCardIsActive" -->
            <el-input
              :maxlength="7"
              v-model.trim="val.plateNumber"
              placeholder="请输入车牌号"
            ></el-input>
          </el-form-item>
          车牌颜色
          <el-form-item
            :prop="'plateList.' + ind + '.plateColor'"
            :rules="[{ required: true, message: '请选择车牌颜色', trigger: 'change' }]"
            style="width: 100px; display: inline-block; margin-right: 10px"
          >
            <el-select v-model.trim="val.plateColor" placeholder="请选择">
              <el-option
                :label="v.desc"
                :value="v.code"
                v-for="v in colorList"
                :key="v.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-button class="button-style" v-if="ind == 0" type="primary" plain @click="addNumber">
            <i class="el-icon-plus icon-style"></i>
          </el-button>
          <el-button class="button-style" v-else type="warning" plain @click="deleteNumber(ind)">
            <i class="el-icon-minus icon-style"></i>
          </el-button>
        </el-form-item>
      </div>

      <el-form-item label="手机号:" v-if="formInline.phoneNumber">{{
        formInline.phoneNumber
      }}</el-form-item>
      <el-row>
        <el-col :span="8">
          <el-form-item label="购买渠道:">{{ formInline.buyTypeDesc }}</el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="实付金额:">{{ formInline.actualAmount | priceFilter }}</el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="支付渠道:">{{ formInline.channelTypeDesc }}</el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="支付设备:">{{ formInline.devTypeDesc }}</el-form-item>
        </el-col>
      </el-row>
      <div>
        <div class="section-title">商品信息</div>
        <el-form-item label="商品名称:">{{ formInline.parkCardTitle }}</el-form-item>
        <el-form-item label="商户名称:">{{ formInline.operatorName }}</el-form-item>
        <el-form-item label="适用范围:">{{ formInline.useRangeDesc }}</el-form-item>
        <el-form-item label="生效开始日期:">{{
          formInline.startDate | timeFilter(that)
        }}</el-form-item>
        <el-form-item label="有效天数:" v-if="formInline.validityPeriod">{{
          formInline.validityPeriod
        }}</el-form-item>
        <el-form-item label="生效结束日期:">{{
          formInline.endDate | timeFilter(that)
        }}</el-form-item>
        <el-form-item label="车位限制:" v-if="formInline.carMaxNum"
          >{{ formInline.carMaxNum }}辆</el-form-item
        >
      </div>
      <!-- <div v-if="orderOtherParkCard.length > 0">
          <div v-for="(item,index) in orderOtherParkCard" :key="index">
          <div class="section-title">商品信息</div>
          <el-form-item label="商品名称:">{{ formInline.parkCardTitle }}</el-form-item>
          <el-form-item label="商户名称:">{{ formInline.operatorName }}</el-form-item>
          <el-form-item label="适用范围:">{{ formInline.useRangeDesc }}</el-form-item>
          <el-form-item label="生效开始日期:">{{ item.startDate | timeFilter(that) }}</el-form-item>
          <el-form-item label="有效天数:" v-if="item.validityPeriod">{{ item.validityPeriod }}</el-form-item>
          <el-form-item label="生效结束日期:">{{ item.endDate | timeFilter(that) }}</el-form-item>
      </div>
      </div> -->
      <div>
        <div class="section-title">车牌绑定信息</div>
        <el-table
          header-cell-class-name="header-call-style"
          border
          
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column type="index" :label="$t('list.index')" width="100" align="center"></el-table-column>
          <el-table-column
            type="parkCardHistory"
            :label="$t('searchModule.plate_number')"
            align="center"
            show-overflow-tooltip
            width="120"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.plateColor }} : {{ scope.row.plateNumberNew }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
    </el-form>
    <div style="text-align: center; margin-top: 10px">
      <el-button type="primary" @click="saveEdit" v-if="$route.query.isEdit">{{ $t('button.preservation') }}</el-button>
      <el-button @click="$router.history.go(-1)">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "parkRuleDetail",
  filters: {
    priceFilter(value) {
      if (!value) return;
      return (value / 100).toFixed(2) + "元";
    },
    timeFilter(value, that) {
      if (!value) return;
      return that.$moment(parseInt(value)).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  data() {
    const plateNumberRules = (rule, value, callback) => {
      let rel =
        /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z0-9]{1}[A-Z0-9]{1}[A-Z0-9]{4,5}([A-Z0-9挂学警港澳使]{1}|应急)$/;
      if (rel.test(value)) {
        callback();
      } else {
        callback("车牌号格式不正确，例：冀AAAAAA");
      }
    };
    return {
      formInline: {
        plateNumber: "",
        plateColorCode: "",
        plateList: [
          {
            numberName: "",
            plateNumber: "",
            plateColor: "",
          },
        ],
      },
      orderOtherParkCard: [],
      that: this,
      colorList: [],
      platerNumberLists: [
        {
          numberId: "11",
          numberName: "京",
        },
        {
          numberId: "12",
          numberName: "津",
        },
        {
          numberId: "13",
          numberName: "冀",
        },
        {
          numberId: "14",
          numberName: "晋",
        },
        {
          numberId: "15",
          numberName: "蒙",
        },
        {
          numberId: "21",
          numberName: "辽",
        },
        {
          numberId: "22",
          numberName: "吉",
        },
        {
          numberId: "23",
          numberName: "黑",
        },
        {
          numberId: "31",
          numberName: "沪",
        },
        {
          numberId: "32",
          numberName: "苏",
        },
        {
          numberId: "33",
          numberName: "浙",
        },
        {
          numberId: "34",
          numberName: "皖",
        },
        {
          numberId: "35",
          numberName: "闽",
        },
        {
          numberId: "36",
          numberName: "赣",
        },
        {
          numberId: "37",
          numberName: "鲁",
        },
        {
          numberId: "41",
          numberName: "豫",
        },
        {
          numberId: "42",
          numberName: "鄂",
        },
        {
          numberId: "43",
          numberName: "湘",
        },
        {
          numberId: "44",
          numberName: "粤",
        },
        {
          numberId: "45",
          numberName: "桂",
        },
        {
          numberId: "46",
          numberName: "琼",
        },
        {
          numberId: "50",
          numberName: "渝",
        },
        {
          numberId: "51",
          numberName: "川",
        },
        {
          numberId: "52",
          numberName: "黔",
        },
        {
          numberId: "53",
          numberName: "滇",
        },
        {
          numberId: "54",
          numberName: "藏",
        },
        {
          numberId: "61",
          numberName: "陕",
        },
        {
          numberId: "62",
          numberName: "甘",
        },
        {
          numberId: "63",
          numberName: "青",
        },
        {
          numberId: "64",
          numberName: "宁",
        },
        {
          numberId: "65",
          numberName: "新",
        },
        {
          numberId: "71",
          numberName: "台",
        },
        {
          numberId: "81",
          numberName: "港",
        },
        {
          numberId: "82",
          numberName: "澳",
        },
        {
          numberId: "90",
          numberName: "外",
        },
      ],
      tableData: [],
      tableCols: [
        {
          prop: "phoneNumberNew",
          label: this.$t("list.phone_number"),
          width: "",
        },
        {
          prop: "createdTime",
          label: this.$t("list.Binding_time"),
          width: "",
        },
        {
          prop: "operationType",
          label: this.$t("list.Operation_type"),
          width: "",
          formatter: (row) => {
            return row.operationType == 1
              ? "绑定"
              : row.operationType == 2
              ? "变更"
              : row.operationType == 3
              ? "删除"
              : "";
          },
        },
        {
          prop: "plateNumberOld",
          label: "变更信息",
          width: "",
          formatter: (row) => {
            if (row.operationType == 2) {
              return row.plateNumberOld + "->" + row.plateColor + ":" + row.plateNumberNew;
            } else {
              return "-";
            }
          },
        },
        {
          prop: "carMaxNumRemain",
          label: "车位限制剩余",
          width: "",
        },
        {
          prop: "operateName",
          label: this.$t("list.Operator"),
          width: "",
          formatter: (row) => {
            return !row.operateName ? row.phoneNumberNew : row.operateName;
          },
        },
      ],
      rules: {
        plateNumber: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
          {
            validator: plateNumberRules,
            trigger: "blur",
          },
        ],
        plateColorCode: [
          {
            required: true,
            message: "必填",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    if (this.$route.query.isEdit) {
      this.getColor();
    }
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$axios
        .get(`/acb/2.0/parkCard/detail`, {
          data: {
            parkCardId: this.$route.query.parkCardId,
            // orderId: this.$route.query.orderId
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.formInline = res.value;
            if (this.$route.query.isEdit) {
              let plateArr = [];
              if (this.formInline.plateList.length > 0) {
                this.formInline.plateList.forEach((plate) => {
                  plateArr.push({
                    numberName: plate.plateNumber.slice(0, 1),
                    plateNumber: plate.plateNumber.slice(1),
                    plateColor: plate.plateColor,
                    colorId: plate.plateColorCode,
                    carId: plate.carId,
                  });
                });
                this.formInline.plateList = plateArr;
              } else {
                this.formInline.plateList = [
                  {
                    numberName: "",
                    plateNumber: "",
                    plateColor: "",
                  },
                ];
              }
            }

            // this.orderOtherParkCard = res.value.orderOtherParkCard ? res.value.orderOtherParkCard : [];
            this.tableData = res.value.parkCardHistory;
            if (this.formInline.useRangeDesc === "全部车场") {
              this.getScopes(res.value.operationId);
            }
          } else {
            this.$alert(res.desc);
          }
        });
    },
    // 获取全部车场名称
    getScopes(operationId) {
      const opt = {
        url: "/acb/2.0/park/currentUserParkList",
        method: "get",
        data: {
          operationId: operationId,
          dataSource: 0,
        },
        success: (res) => {
          let arr = [];
          res.value.forEach((item) => {
            arr.push(item.parkName);
          });
          this.formInline.useRangeDesc = arr.join("、");
        },
      };
      this.$request(opt);
    },
    getColor() {
      this.$axios.get("/acb/2.0/specialplate/plateColor/list").then((res) => {
        if (res.state == 0) {
          this.colorList = res.value;
        } else {
          this.$alert(res.desc, "提示");
        }
      });
    },
    // 保存编辑
    saveEdit() {
      let plateArr = [];
      if (this.formInline.plateList.length > 0) {
        this.formInline.plateList.forEach((plate) => {
          if (plate.carId) {
            plateArr.push({
              plateColor: plate.colorId,
              plateNumber: plate.numberName + plate.plateNumber,
              carId: plate.carId,
            });
          } else {
            plateArr.push({
              plateColor: plate.plateColor,
              plateNumber: plate.numberName + plate.plateNumber,
            });
          }
        });
      }
      const data = {
        parkCardId: this.$route.query.parkCardId,
        // orderId: this.$route.query.orderId,
        plateList: plateArr,
      };
      // console.log("ddddd", data);
      this.$refs.form.validate((valid) => {
        if (valid) {
          const url = "/acb/2.0/parkCard/updateNew";
          const opt = {
            method: "post",
            url: url,
            config: {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
              },
            },
            data,
            success: (res) => {
              if (res.state == 0) {
                this.$router.history.go(-1);
                this.$message({
                  type: "success",
                  message: res.desc,
                });
              } else {
                this.$alert(res.desc, "提示");
              }
            },
          };
          this.$request(opt);
        }
      });
    },

    checkNumber(rule, value, callback) {
      let rel = "";
      this.formInline.plateList.forEach((el) => {
        if (el.plateColor == 2 || el.plateColor == 3) {
          // 2绿牌、3黄绿牌
          rel = /^[A-Z]{1}[A-Z0-9]{6}$/;
          if (rel.test(value)) {
            callback();
          } else {
            callback("请输入正确的车牌号");
          }
        } else if (el.plateColor == 4 || el.plateColor == 5) {
          // 4黑牌、5白牌
          rel = /^[A-Z]{1}[A-Z0-9]{5,6}$/;
          if (rel.test(value)) {
            callback();
          } else {
            callback("请输入正确的车牌号");
          }
        } else {
          // 蓝牌 黄牌
          rel = /^[A-Z]{1}[A-Z0-9]{5}$/;
          if (rel.test(value)) {
            callback();
          } else {
            callback("请输入正确的车牌号，例：冀AAAAAA");
          }
        }
      });
      // if (value !== "") {
      //   callback(new Error('车牌号不能为空'));
      // }
    },
    addNumber() {
      if (this.formInline.plateList.length < this.formInline.carMaxNum) {
        this.formInline.plateList.push({
          numberName: "",
          plateNumber: "",
          plateColor: "",
        });
      } else {
        if (this.formInline.carMaxNum) {
          this.$message({
            type: "info",
            message: "最多添加" + this.formInline.carMaxNum + "辆",
          });
        } else {
          if (this.formInline.plateList.length >= 3) {
            this.$message({
              type: "info",
              message: "最多添加3辆",
            });
          } else {
            this.formInline.plateList.push({
              numberName: "",
              plateNumber: "",
              plateColor: "",
            });
          }
        }
      }
    },
    deleteNumber(ind) {
      let plateList = this.formInline.plateList;
      plateList.splice(ind, 1);
    },
  },
};
</script>

<style lang="stylus" scoped>
.content {
  padding: 20px;
}

.pageTitle {
  line-height: 30px;
  font-size: 16px;
}

.section-title {
  line-height: 35px;
  background-color: #ccc;
  color: #656565;
  margin: 10px 0;
  font-size: 14px;
  padding-left: 20px;
}
</style>
